import React, { useState } from "react";
import CountUp from "react-countup";
import { LinksExt, LinksInt } from "../data";
import { Link } from "gatsby";

const PricingSection = () => {
  const [annualChecked, setAnnualChecked] = useState(true);
  const annualToggle = () => {
    setAnnualChecked(!annualChecked);
  };
  return (
    <section className="pt-9 pt-md-12 bg-gray-200">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">
            {/* <!-- Heading --> */}
            <h1>Fair, simple pricing for all.</h1>

            {/* <!-- Text --> */}
            <p className="lead text-gray-700">
              30 days free trial, annual subscription discount, discounts for
              larger organizations.
            </p>

            {/* <!-- Form --> */}
            <form className="d-flex align-items-center justify-content-center mb-7 mb-md-9">
              {/* <!-- Label --> */}
              <span className="text-muted">Monthly</span>

              {/* <!-- Switch --> */}
              <div className="form-check form-switch mx-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="billingSwitch"
                  data-toggle="price"
                  data-target=".price"
                  checked={annualChecked}
                  onChange={annualToggle}
                />
              </div>

              {/* <!-- Label --> */}
              <span className="text-muted">Annual</span>
            </form>
          </div>
        </div>
        {/* <!-- / .row --> */}
        <div className="row align-items-center gx-0">
          <div className="col-12 col-md-6">
            {/* <!-- Card --> */}
            <div
              className="card rounded-lg shadow-lg mb-6 mb-md-0"
              style={{ zIndex: 1 }}
              data-aos="fade-up"
            >
              {/* <!-- Body --> */}
              <div className="card-body py-6 py-md-8">
                <div className="row justify-content-center">
                  <div className="col-12 col-xl-9">
                    {/* <!-- Badge --> */}
                    <div className="text-center mb-5">
                      <span className="badge rounded-pill bg-primary-soft">
                        <span className="h6 fw-bold text-uppercase">
                          Standard
                        </span>
                      </span>
                    </div>

                    {/* <!-- Price --> */}
                    <div className="d-flex justify-content-center">
                      <span className="h2 mb-0 mt-2">$</span>
                      <span className="price display-2 mb-0">
                        {annualChecked && (
                          <CountUp start={49} end={29} duration={1} />
                        )}
                        {!annualChecked && (
                          <CountUp start={29} end={49} duration={1} />
                        )}
                      </span>
                      <span className="h2 align-self-end mb-1">/mo</span>
                    </div>

                    {/* <!-- Text --> */}
                    <p className="text-center text-muted mb-6 mb-md-8">
                      per seat
                    </p>

                    {/* <!-- Features --> */}
                    <div className="d-flex">
                      {/* <!-- Check --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>

                      {/* <!-- Text --> */}
                      <p>30 days free trial</p>
                    </div>
                    <div className="d-flex">
                      {/* <!-- Check --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>

                      {/* <!-- Text --> */}
                      <p>
                        All channel integrations - WhatsUp, Facebook, Livechat,
                        Email, SMS, Voice and more...
                      </p>
                    </div>
                    <div className="d-flex">
                      {/* <!-- Check --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>

                      {/* <!-- Text --> */}
                      <p>AI powered chatbots</p>
                    </div>
                    <div className="d-flex">
                      {/* <!-- Check --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>

                      {/* <!-- Text --> */}
                      <p>Payment gateways</p>
                    </div>
                    <div className="d-flex">
                      {/* <!-- Check --> */}
                      <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                        <i className="fe fe-check"></i>
                      </div>

                      {/* <!-- Text --> */}
                      <p className="mb-0">Free support</p>
                    </div>
                  </div>
                </div>
                {/* <!-- / .row --> */}
              </div>

              {/* <!-- Button --> */}
              <a
                href={LinksExt.SignUp}
                className="card-btn btn w-100 btn-lg btn-primary"
              >
                Start Now
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6 ms-md-n3">
            {/* <!-- Card --> */}
            <div
              className="card rounded-lg shadow-lg"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              {/* <!-- Body --> */}
              <div className="card-body py-6 py-md-8">
                <div className="row justify-content-center">
                  <div className="col-12 col-xl-10">
                    {/* <!-- Badge --> */}
                    <p className="text-center mb-8 mb-md-11">
                      <span className="badge rounded-pill bg-primary-soft">
                        <span className="h6 fw-bold text-uppercase">
                          Enterprise
                        </span>
                      </span>
                    </p>

                    {/* <!-- Text --> */}
                    <p className="lead text-center text-muted mb-0 mb-md-10">
                      We offer variable pricing with discounts for larger
                      organizations. Get in touch with us and we’ll figure out
                      something that works for everyone.
                    </p>
                  </div>
                </div>
                {/* <!-- / .row --> */}
              </div>

              {/* <!-- Button --> */}
              <Link
                to={LinksInt.Contact}
                className="card-btn btn w-100 btn-lg btn-light bg-gray-300 text-gray-700"
              >
                Contact us
              </Link>
            </div>
          </div>
        </div>
        {/* <!-- / .row --> */}
      </div>
      {/* <!-- / .container --> */}
    </section>
  );
};

export default PricingSection;
