import React from "react";
import { Link } from "gatsby";
import { LinksInt, LinksExt } from "../data";

const TermsOfServiceSection = () => {
  return (
    <section className="pt-8 pt-md-11 pb-8 pb-md-14 bg-gradient-light-white">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md">
            {/* <!-- Heading --> */}
            <h1 className="display-4 mb-2">Terms of Service</h1>

            {/* <!-- Text --> */}
            <p className="fs-lg text-gray-700 mb-md-0">Updated 11/07/2021</p>
          </div>
        </div>
        {/* <!-- / .row --> */}
        <div className="row">
          <div className="col-12">
            {/* <!-- Divider --> */}
            <hr className="my-6 my-md-8" />
          </div>
        </div>{" "}
        {/* <!-- / .row --> */}
        <div className="row">
          <div className="col-12 col-md-8">
            {/* <!-- Text --> */}
            <p className="text-gray-800">
              These Terms of Service are a contract between you and ChatIQ, Inc.
              By using the Services of ChatIQ on the web or on any other
              applications that give access to ChatIQ (desktop or mobile
              applications), you are bound to agree to the following Terms of
              Use.
            </p>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              If a user violates any of the terms outlined below, we reserve the
              right to cancel accounts or bar access to accounts without notice.
              If you do not agree to these terms, please do not use our
              Services.
            </p>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              ChatIQ reserves the right to update and modify the Terms of Use at
              any time without notice. New features that may be added to the
              Service shall be subject to the Terms of Use. Should you continue
              to use the Service after any such modifications have been made,
              this shall constitute your agreement to such modifications.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>1 - Account Terms</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              You must be a human. Accounts registered by “bots” or other
              automated methods are not permitted.
            </p>
            <p className="text-gray-800">
              You must provide your full legal name, a valid email address, and
              any other required information to complete the sign-up process.
            </p>
            <p className="text-gray-800">
              You are responsible for maintaining the privacy and security of
              your account. ChatIQ will not be held liable for any damage or
              loss that may result from your failure to protect your login
              information, including your password.
            </p>
            <p className="text-gray-800">
              One person or legal entity may not maintain more than one free
              account.
            </p>
            <p className="text-gray-800">
              ChatIQ may communicate with you via email or pushed notifications
              regarding your account, system updates, or other issues related to
              your account.
            </p>
            <p className="text-gray-800">
              You are responsible for all Content send and activity that occurs
              under your account (even when Content is sent by others to your
              account).
            </p>
            <p className="text-gray-800">
              You may not use ChatIQ for any illegal or unauthorized purpose.
              You must not, in the use of ChatIQ, violate any laws in your
              jurisdiction (including but not limited to copyright laws).
            </p>
            <p className="text-gray-800">
              Your login details may be used up to a maximum of five concurrent
              sessions.
            </p>
            <p className="text-gray-800">
              ChatIQ may refuse service to anyone for any reason at any time.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>2 - Payment and Access</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              A valid credit card is required for paying accounts.
            </p>
            <p className="text-gray-800">
              Free accounts are not required to provide a credit card number.
            </p>
            <p className="text-gray-800">
              Should you upgrade or downgrade your premium plan, you will be
              charged your new billing rate immediately.
            </p>
            <p className="text-gray-800">
              The ChatIQ Service is billed in advance in accordance with our
              pricing schedule and all monthly payments are nonrefundable.
            </p>
            <p className="text-gray-800">
              There will be no refunds or credits for partial months of service,
              upgrade/downgrade refunds, or refunds for months unused with an
              open account. In order to treat everyone equally, no exceptions
              will be made.
            </p>
            <p className="text-gray-800">
              Our company policy is to not extend discounts for a period of more
              than 12 months.
            </p>
            <p className="text-gray-800">
              All fees are exclusive of all taxes, levies, or duties imposed by
              taxing authorities, and you shall be responsible for payment of
              all such taxes, levies, or duties, excluding only United States
              (federal or state) taxes. You agree to pay for any such taxes that
              might be applicable to your use of ChatIQ and payments made by you
              herein.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>3 - Modifications to the Service and Fees</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              ChatIQ reserves the right to modify, suspend, or discontinue the
              Service at any time for any reason with or without notice.
            </p>
            <p className="text-gray-800">
              ChatIQ reserves the right to change our monthly/annually fees upon
              30 days notice from us. The fee change will be notified per email
              to all our subscribers and will be reflected on the pricing page
              at <Link to={LinksInt.Pricing}>chatiq.net/pricing</Link>.
            </p>
            <p className="text-gray-800">
              ChatIQ reserves the right to update and change the Terms of
              Service from time to time without notice. Any new features that
              augment or enhance the current Service, including the release of
              new tools and resources, shall be subject to the Terms of Service.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>4 - Cancellation and Termination</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              You alone are responsible for properly canceling your Account. An
              email or phone request to cancel your Account shall result in
              cancellation. Any cancellation of your Account will result in the
              deactivation or deletion of your Account or your access to your
              Account, and the forfeiture and relinquishment of all Content in
              your Account. This information cannot be recovered from ChatIQ
              once your account is canceled. Please be aware of that. You can
              cancel at any time, but you will remain liable for all charges
              accrued up to that time, including full monthly charges for the
              month which you discontinued service. You will not be charged
              again.
            </p>
            <p className="text-gray-800">
              ChatIQ reserves the right to (i) modify or discontinue,
              temporarily or permanently, the Service (or any part thereof) and
              (ii) refuse any and all current and future use of the Service,
              suspend or terminate your account (any part thereof) or use of the
              Service and remove and discard any of Your Content in the Service,
              for any reason, including if ChatIQ believes that You have
              violated these Terms of Use.
            </p>
            <p className="text-gray-800">
              ChatIQ will use all reasonable efforts to contact You directly via
              email to warn You prior to suspension or termination of Your
              account. Any suspected fraudulent, abusive, or illegal activity
              that may be grounds for termination of Your use of Service, may be
              referred to appropriate law enforcement authorities. ChatIQ shall
              not be liable to You or any third party for any modification,
              suspension or discontinuation of the Service.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>5 - Copyright and Ownership</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              ChatIQ or its suppliers own the intellectual property rights to
              any and all protectable components of the Service, including but
              not limited to the name of the Service, artwork and end-user
              interface elements contained within the Service, and many of the
              individual features. You may not copy, modify, adapt, reproduce,
              distribute, reverse engineer, decompile, or disassemble any aspect
              of the Service which ChatIQ or its suppliers own.
            </p>
            <p className="text-gray-800">
              ChatIQ claims no intellectual property rights over the Content you
              upload or provide to the Service.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>6 - General Conditions</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              Your use of the Service, including any Content, information or
              functionality contained within it, is provided “as is” and “as
              available” with no representations or warranties of any kind,
              either expressed or implied, including but not limited to, the
              implied warranties of merchantability, fitness for a particular
              purpose, and non-infringement. You assume total responsibility and
              risk for your use of these Service.
            </p>
            <p className="text-gray-800">
              You agree not to resell, duplicate, reproduce or exploit any part
              of the Service without the express written permission of ChatIQ.
            </p>
            <p className="text-gray-800">
              You agree not to export or collect, by any electronic or other
              means, email addresses or other information of other users of the
              Service for the purpose of sending emails or other unsolicited
              correspondence.
            </p>
            <p className="text-gray-800">
              You agree not to act in a way that risks damaging, deactivating,
              or overloading the Service or its infrastructure.
            </p>
            <p className="text-gray-800">
              You agree not to distribute anything containing a computer virus
              or any code, file or software program intended to interrupt,
              destroy or limit the functionality of the Service or its
              infrastructure.
            </p>
            <p className="text-gray-800">
              You agree to comply with all applicable legal terms and conditions
              of other products and services offered in ChatIQ, including,
              without limitation,{" "}
              <a href="https://twitter.com/en/tos">
                Twitter's Terms of Service.
              </a>
            </p>
            <p className="text-gray-800">
              ChatIQ makes no warranties regarding (i) your ability to use the
              Service, (ii) your satisfaction with the Service, (iii) that the
              Service will be available at all times, uninterrupted, and
              error-free (iv), the accuracy of mathematical calculations
              performed by the Service, and (v) that bugs or errors in the
              Service will be corrected. ChatIQ and its affiliates and its
              sponsors are neither responsible nor liable for any direct,
              indirect, incidental, consequential, special, exemplary, punitive
              or other damages arising out of or relating in any way to your use
              of the Service. Your sole remedy for dissatisfaction with the
              Service is to stop using the Service.
            </p>
            <p className="text-gray-800">
              To the extent that ChatIQ processes any personal data that is
              subject to the General Data Protection Regulation (or GDPR), on
              your behalf, in the provision of the Services, the terms of the
              ChatIQ Data Processing Addendum at Exhibit 1 shall apply.
            </p>
            <p className="text-gray-800">
              If any provision of the Terms of Use is held invalid or otherwise
              unenforceable, the enforceability of the remaining provisions
              shall not be impaired thereby.
            </p>
            <p className="text-gray-800">
              ChatIQ may, but have no obligation to, remove Content and Accounts
              containing Content that we determine in our sole discretion are
              unlawful, offensive, threatening, libelous, defamatory,
              pornographic, obscene or otherwise objectionable or violates any
              party’s intellectual property or these Terms of Use.
            </p>
            <p className="text-gray-800">
              The failure of ChatIQ to exercise any right provided for herein
              shall not be deemed a waiver of any right hereunder. The Terms of
              Use sets forth the entire understanding between you and ChatIQ as
              to the Service and supersedes any prior agreements between you and
              ChatIQ (including, but not limited to, prior versions of the Terms
              of Use).
            </p>
            <p className="text-gray-800">
              Any questions regarding the Terms of Use should be addressed to{" "}
              <a href={`mailto:${LinksExt.SupportEmail}`}>
                {LinksExt.SupportEmail}
              </a>
              .
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>7 - Governing Law</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              Unless otherwise required by law – and which shall only be applied
              strictly for its intended purpose, it is expressly agreed that US
              law shall be the law applicable to these Terms of Use and any
              litigation between you and ChatIQ, Inc. related to the use of the
              ChatIQ Service shall be submitted to the jurisdiction of a
              competent court in Delaware. These Terms of Service will be
              governed by and construed in accordance with the laws of the State
              of Delaware, without giving effect to its conflict of law
              provisions or your actual state or country of residence. If for
              any reason a court of competent jurisdiction finds any provision
              or portion of the Terms of Service to be unenforceable, the
              remainder of the Terms of Service will continue in full force and
              effect.
            </p>

            {/* <!-- Heading --> */}
            <h2>Exhibit 1</h2>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>DATA PROCESSING ADDENDUM</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              This Data Processing Addendum (the “Addendum”) is made by and
              between ChatIQ, LLC. (ChatIQ) with registered office in 2450
              Colorado Avenue, Suite 100E, Office 123, Santa Monica, California,
              90404, USA and “Customer”. This Addendum is incorporated into
              ChatIQ Terms of Service (“Agreement”) and applies in respect of
              the provision of the Services to the Customer if the Processing of
              Customer Personal Data (as defined below) is subject to the GDPR,
              only to the extent the Customer is a Controller of Customer
              Personal Data and ChatIQ is a Processor. The Addendum is intended
              to satisfy the requirements of Article 28(3) of the GDPR and,
              prior to the date on which the GDPR takes effect, the requirements
              of Article 17(3) of the EU Data Protection Directive (95/46/EC).
              This Addendum shall be effective for the term of the Agreement.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>1. Definitions</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800 ms-4">
              1. For the purposes of the Addendum:
            </p>
            <p className="text-gray-800 ms-7">
              1.1. "Customer Personal Data" means the Personal Data described
              under Annex 1 of this Addendum, in respect of which the Customer
              is the Controller;
            </p>
            <p className="text-gray-800 ms-7">
              1.2. “Data Protection Legislation” means all applicable
              legislation relating to data protection and privacy including
              without limitation the EU Data Protection Directive 95/46/EC and
              Directive 2002/58/EC and all local laws and regulations which
              amend or replace any of them, including the GDPR, together with
              any national implementing laws in any Member State of the European
              Union or, to the extent applicable, in any other country, as
              amended, repealed, consolidated or replaced from time to time;
            </p>
            <p className="text-gray-800 ms-7">
              1.3. “GDPR” means the General Data Protection Regulation (EU)
              2016/679 of the European Parliament and of the Council of 27 April
              2016 on the protection of natural persons with regard to the
              processing of personal data and on the free movement of such data;
              and
            </p>
            <p className="text-gray-800 ms-7">
              1.4. “Personal Data”, “Data Subject”, “Personal Data Breach”,
              “Process”, “Processor” and “Controller” will each have the meaning
              given to them in the GDPR.
            </p>
            <p className="text-gray-800 ms-4">
              2. Capitalized terms not otherwise defined herein shall have the
              meaning given to them in the Agreement.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>2. Processing of Customer Personal Data</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800 ms-4">
              1. The parties acknowledge and agree that Customer is the
              Controller of Customer Personal Data and ChatIQ is the Processor
              of that data. ChatIQ will only Process Customer Personal Data as a
              Processor on behalf of and in accordance with the Customer’s prior
              written instructions and for no other purpose. ChatIQ is hereby
              instructed to Process Customer Personal Data to the extent
              necessary to enable ChatIQ to provide the Services in accordance
              with the Agreement.
            </p>
            <p className="text-gray-800 ms-4">
              2. If ChatIQ cannot process Customer Personal Data in accordance
              with Customer’s instructions due to a legal requirement under any
              applicable European Union or Member State law, ChatIQ will (i)
              promptly notify the Customer of such inability, providing a
              reasonable level of detail as to the instructions with which it
              cannot comply and the reasons why it cannot comply, to the
              greatest extent permitted by applicable law; and (ii) cease all
              Processing of the affected Customer Personal Data (other than
              merely storing and maintaining the security of the affected
              Customer Personal Data) until such time as the Customer issues new
              instructions with which ChatIQ is able to comply. If this
              provision is invoked, ChatIQ will not be liable to the Customer
              under the Agreement for failure to perform the Services until such
              time as the Customer issues new instructions. ChatIQ will
              immediately inform Customer if, in its opinion, an instruction
              from Customer infringes the Data Protection Legislation.
            </p>
            <p className="text-gray-800 ms-4">
              3. Each of the Customer and ChatIQ will comply with their
              respective obligations under the Data Protection Legislation.
              Customer shall ensure that Customer has informed its data subjects
              and obtained (or will obtain) all rights and consents (if required
              by the applicable Data Protection Legislation) to allow ChatIQ to
              Process Customer Personal Data to provide the Services in
              accordance with this Addendum and the Agreement.
            </p>
            <p className="text-gray-800 ms-4">
              4. In connection with the performance of the Agreement, Customer
              authorizes ChatIQ to transfer Customer Personal Data from the
              European Economic Area (“EEA”) to the United States. ChatIQ has
              certified to the EU-U.S. and Swiss-U.S. Privacy Shield frameworks
              as administered by the U.S. Department of Commerce and commits to
              comply with its obligations for the Customer Personal Data
              transferred under the Privacy Shield throughout the term of this
              Addendum.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>3. Confidentiality</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800 ms-4">
              1. ChatIQ will ensure that any person whom ChatIQ authorizes to
              Process Customer Personal Data on its behalf is subject to
              confidentiality obligations in respect of that Customer Personal
              Data.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>4. Security Measures</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800 ms-4">
              1. ChatIQ will implement appropriate technical and organizational
              measures to protect against accidental or unlawful destruction,
              loss, alteration, unauthorized disclosure of, or access to
              Customer Personal Data.
            </p>

            <p className="text-gray-800 ms-4">
              2. ChatIQ will, at the Customer’s request and subject to the
              Customer paying all of ChatIQ fees at prevailing rates, and all
              expenses, provide the Customer with reasonable assistance as
              necessary for the fulfillment of the Customer’s obligation to keep
              Customer Personal Data secure.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>5. Sub-Processing</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800 ms-4">
              1. Customer authorizes ChatIQ to appoint sub-processors to perform
              specific services on ChatIQ’s behalf which may require such
              sub-Processors to Process Customer Personal Data. If ChatIQ
              engages a sub-Processor to Process any Customer Personal Data, it
              will:
            </p>

            <div className="d-flex ms-3">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                inform Customer of any intended changes concerning the addition
                or replacement of such sub-Processors and Customer will have an
                opportunity to object to such changes on reasonable grounds
                within thirty (30) calendar days after being notified. If the
                parties are unable to resolve such objection, either party may
                terminate the agreement by providing written notice to the other
                party; and
              </p>
            </div>

            <div className="d-flex ms-3">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                enter into a binding written agreement with the sub-Processor
                that imposes on the sub-Processor the same obligations that
                apply to ChatIQ under this Addendum. Where any of its sub-
                Processors fails to fulfill its data protection obligations,
                ChatIQ will be liable to the Customer for the performance of its
                sub-Processors’ obligations.
              </p>
            </div>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>6. Data Subject Rights</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800 ms-4">
              1. ChatIQ will, at the Customer’s request and subject to the
              Customer paying all of ChatIQ’s fees at prevailing rates, and all
              expenses, provide the Customer with the assistance necessary for
              the fulfillment of the Customer’s obligation to respond to
              requests for the exercise of Data Subjects’ rights. ChatIQ shall
              not respond to such requests without the Customer’s prior written
              consent and written instructions. The Customer shall be solely
              responsible for responding to such requests.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>7. Personal Data Breaches</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800 ms-4">
              1. ChatIQ will notify the Customer as soon as practicable after it
              becomes aware of any Personal Data Breach affecting any Customer
              Personal Data. At the Customer’s request and subject to the
              Customer paying all of ChatIQ’s fees at prevailing rates, and all
              expenses, ChatIQ will promptly provide the Customer with all
              reasonable assistance necessary to enable the Customer to notify
              relevant security breaches to the competent data protection
              authorities and/or affected Data Subjects, if Customer is required
              to do so under the GDPR. The Customer is solely responsible for
              complying with data incident notification requirements applicable
              to Customer and fulfilling any third-party notification
              obligations related to any data incidents.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>
                8. Data Protection Impact Assessment; Prior Consultation
              </strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800 ms-4">
              1. ChatIQ will, at the Customer’s request and subject to the
              Customer paying all of ChatIQ’s fees at prevailing rates, and all
              expenses, provide the Customer with reasonable assistance to
              facilitate:
            </p>

            <div className="d-flex ms-3">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                conduction of data protection impact assessments if the Customer
                is required to do so under the GDPR; and
              </p>
            </div>

            <div className="d-flex ms-3">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                consultation with data protection authorities, if the Customer
                is required to engage in consultation under the GDPR,
              </p>
            </div>

            <p className="text-gray-800 ms-4">
              in each case solely to the extent that such assistance is
              necessary and relates to the Processing by ChatIQ of the Customer
              Personal Data, taking into account the nature of the Processing
              and the information available to ChatIQ.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>9. Deletion of Customer Personal Data</strong>
            </h3>

            <p className="text-gray-800 ms-4">
              1. ChatIQ will return or delete, at Customer’s choice, Customer
              Personal Data to the Customer after the end of the provision of
              Services relating to the Processing, and delete existing copies
              unless the applicable European Union or member state law requires
              storage of the data.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>10. Information</strong>
            </h3>

            <p className="text-gray-800 ms-4">
              1. ChatIQ will, at Customer’s request and subject to the Customer
              paying all of ChatIQ’s fees at prevailing rates, and all expenses,
              provide the Customer with all information necessary to enable the
              Customer to demonstrate compliance with its obligations under the
              GDPR, and allow for and contribute to audits, including
              inspections, conducted by the Customer or an auditor mandated by
              the Customer, to the extent that such information is within
              ChatIQ’s control and ChatIQ is not precluded from disclosing it by
              applicable law, a duty of confidentiality, or any other obligation
              owed to a third party, and provided that such audits shall be
              carried out with reasonable notice during regular business hours
              not more often than once per year.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>11. Liability</strong>
            </h3>

            <p className="text-gray-800 ms-4">
              1. Each party’s liability towards the other party under or in
              connection with this Addendum will be limited in accordance with
              the provisions of the Agreement.
            </p>

            <p className="text-gray-800 ms-4">
              2. The Customer acknowledges that ChatIQ is reliant on the
              Customer for direction as to the extent to which ChatIQ is
              entitled to Process Customer Personal Data on behalf of Customer
              in the performance of the Services. Consequently, ChatIQ will not
              be liable under the Agreement for any claim brought by a Data
              Subject arising from any action or omission by ChatIQ, to the
              extent that such action or omission resulted directly from the
              Customer’s instructions or from Customer’s failure to comply with
              its obligations under the applicable data protection law.
            </p>

            {/* <!-- Heading --> */}
            <h2>Annex 1</h2>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>Details of the Processing Activities</strong>
            </h3>

            <p className="text-gray-800 ">
              <strong>Categories of Data Subjects:</strong>
            </p>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                Prospects and customers of the Customer;
              </p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                Customer’s Users authorized by Customer to use the Services;
              </p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                Employees or contact persons of Customer’s prospects, customers,
                business partners and vendors; and
              </p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                End-users: individuals who interact with the Customer by way of
                the ChatIQ communication platform.
              </p>
            </div>

            <p className="text-gray-800 ">
              <strong>Type of Personal Data:</strong> Customer may submit
              Personal Data to the Services, the extent of which is determined
              and controlled by Customer in its sole discretion, and which may
              include, but is not limited to the following categories of
              Personal Data:
            </p>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">First and last name</p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">Title</p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">Job Title</p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                Contact information (company, email, phone, physical business
                address, social networks)
              </p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">IP address</p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">Localization data</p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">Signature</p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">Picture</p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                Interactions with end-users via the communication platform
                (messages, calendar invites, attached files, pictures, videos)
              </p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">Web application usage data</p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                Data relating to data subjects’ interactions with email
                communications in connection with the ChatIQ’s email tracking
                feature
              </p>
            </div>

            <p className="text-gray-800">
              <strong>Subject-Matter and Nature of the Processing:</strong> The
              subject-matter of Processing of Customer Personal Data is the
              performance of the Services pursuant to the Agreement. Customer
              Personal Data will be stored by ChatIQ and subject to those
              Processing activities which are necessary for the performance of
              the Services pursuant to the Agreement.
            </p>

            <p className="text-gray-800">
              <strong>Purpose of the Processing:</strong> Customer Personal Data
              will be Processed by ChatIQ for purposes of providing the Services
              set out into the Agreement and any applicable statement of work.
            </p>

            <p className="text-gray-800">
              <strong>Duration of the Processing:</strong> Customer Personal
              Data will be Processed for the duration of the Agreement, subject
              to Section 9 of this Addendum.
            </p>
          </div>

          <div className="col-12 col-md-4">
            {/* <!-- Card --> */}
            <div className="card shadow-light-lg">
              <div className="card-body">
                {/* <!-- Heading --> */}
                <h4>Have a question?</h4>

                {/* <!-- Text --> */}
                <p className="fs-sm text-gray-800 mb-5">
                  Not sure exactly what we’re looking for or just want
                  clarification? We’d be happy to chat with you and clear things
                  up for you. Anytime!
                </p>

                {/* <!-- Heading --> */}
                <h6 className="fw-bold text-uppercase text-gray-700 mb-2">
                  Email us
                </h6>

                {/* <!-- Text --> */}
                <p className="fs-sm mb-0">
                  <a
                    href={`mailto:${LinksExt.SupportEmail}`}
                    className="text-reset"
                  >
                    {LinksExt.SupportEmail}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* {/* <!-- / .row --> */}
      </div>
      {/* {/* <!-- / .container --> */}
    </section>
  );
};

export default TermsOfServiceSection;
