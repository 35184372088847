import React from "react";
import Address from "../components/Address";

const AboutInfoSection = () => {
  return (
    <section className="pt-8 pt-md-11 pb-8 pb-md-14 bg-gradient-light-white">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* <!-- Heading --> */}
            <h1 className="mb-5">We Power Customer Support</h1>
            {/* <!-- Text --> */}
            <p className="text-gray-800">
              We help companies communicate with customers more effectively and
              efficiently. ChatIQ is a conversational customer support platform
              that helps businesses to communicate with customers across any
              channel. ChatIQ offers an AI-based chatbot that automates up to
              60% of the customer inquiries. In addition to automation, ChatIQ’s
              customer management platform offers an intuitive feature-rich
              interface to unify customer messaging from multiple channels into
              a single screen.
            </p>
            {/* <!-- Text --> */}
            <p className="text-gray-800">
              ChatIQ offers tools and omnichannel solutions to help businesses
              streamline conversations through their customers’ preferred
              channels — like SMS, Voice, WhatsApp, WeChat, Messenger, Email and
              Live Chat, and other social messengers. ChatIQ gives agents a
              powerful platform to enhance the customer support and end
              consumers instant, safe and secure access to information they
              need. It also means they can spend less time on hold, avoid
              repeating stories to multiple agents, and get more time to do what
              they love.
            </p>
            {/* <!-- Text --> */}
            <p className="text-gray-800">
              Headquartered in Santa Monica, California, USA, ChaitIQ is
              currently powering the customer support for more than 550
              companies across 16 countries. With more than 2,000 agents using
              the omnichannel platform for conversational customer support.
            </p>

            {/* <!-- Heading --> */}
            <h1 className="mb-5 mt-5">Our Offices</h1>
            {/* <!-- Text --> */}
            <Address />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutInfoSection;
