import React from "react";
import Svg from "react-inlinesvg";
import Server from "../images/duotone-icons/devices/server.svg";
import Timer from "../images/duotone-icons/home/timer.svg";
import Chat from "../images/duotone-icons/communication/group-chat.svg";

const FeaturesDetailsSection = () => {
  return (
    <section className="py-8 py-md-11 border-bottom">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4" data-aos="fade-up">
            {/* <!-- Icon --> */}
            <div className="icon text-primary mb-3">
              <Svg width="24" height="24" src={Server} />
            </div>

            {/* <!-- Heading --> */}
            <h3>Built to scale</h3>

            {/* <!-- Text --> */}
            <p className="text-muted mb-6 mb-md-0">
              Cloud-based solution to meet the requirements of an enterprise of
              any size.
            </p>
          </div>
          <div
            className="col-12 col-md-4"
            data-aos="fade-up"
            data-aos-delay="50"
          >
            {/* <!-- Icon --> */}
            <div className="icon text-primary mb-3">
              <Svg width="24" height="24" src={Timer} />
            </div>

            {/* <!-- Heading --> */}
            <h3>Easy to start</h3>

            {/* <!-- Text --> */}
            <p className="text-muted mb-6 mb-md-0">
              Get started in minutes, no technical knowledge required. Simple
              and intuitive interface.
            </p>
          </div>
          <div
            className="col-12 col-md-4"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            {/* <!-- Icon --> */}
            <div className="icon text-primary mb-3">
              <Svg width="24" height="24" src={Chat} />
            </div>

            {/* <!-- Heading --> */}
            <h3>Truly omnichannel</h3>

            {/* <!-- Text --> */}
            <p className="text-muted mb-0">
              From voice to email, chat to SMS, social to knowledge base,
              all-in-one customer support solution.
            </p>
          </div>
        </div>
        {/* <!-- / .row --> */}
      </div>
      {/* <!-- / .container --> */}
    </section>
  );
};

export default FeaturesDetailsSection;
