import React from "react";
import Svg from "react-inlinesvg";
import Settings from "../images/duotone-icons/general/settings-1.svg";
import LayoutArrange from "../images/duotone-icons/layout/layout-arrange.svg";
import Chat from "../images/duotone-icons/communication/chat-2.svg";

const DetailsSection = () => {
  return (
    <section className="py-8 py-md-11 border-bottom">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4" data-aos="fade-up">
            {/* <!-- Icon --> */}
            <div className="icon text-primary mb-3">
              <Svg width="24" height="24" src={Settings} />
            </div>

            {/* <!-- Heading --> */}
            <h3>Built for companies</h3>

            {/* <!-- Text --> */}
            <p className="text-muted mb-6 mb-md-0">
              ChatIQ helps you to communicate with your customers at any time
              and deliver personalized support across all communication
              channels.
            </p>
          </div>
          <div
            className="col-12 col-md-4"
            data-aos="fade-up"
            data-aos-delay="50"
          >
            {/* <!-- Icon --> */}
            <div className="icon text-primary mb-3">
              <Svg width="24" height="24" src={LayoutArrange} />
            </div>

            {/* <!-- Heading --> */}
            <h3>Designed to be modern</h3>

            {/* <!-- Text --> */}
            <p className="text-muted mb-6 mb-md-0">
              We are focused on building the best customer experience and our
              renewed design supports various features with a high-level user
              experience.
            </p>
          </div>
          <div
            className="col-12 col-md-4"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            {/* <!-- Icon --> */}
            <div className="icon text-primary mb-3">
              <Svg width="24" height="24" src={Chat} />
            </div>

            {/* <!-- Heading --> */}
            <h3>AI-Powered</h3>

            {/* <!-- Text --> */}
            <p className="text-muted mb-0">
              We will help you to automate your company customer support service
              using our AI-powered chatbots.
            </p>
          </div>
        </div>
        {/* <!-- / .row --> */}
      </div>
      {/* <!-- / .container --> */}
    </section>
  );
};

export default DetailsSection;
