import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Logo from "../../images/logos/ChatIQ_Logo_Blue.svg";
import LogoWhite from "../../images/logos/ChatIQ_Logo_White.svg";
import FooterSocial from "./FooterSocial";
import FooterMenu from "./FooterMenu";
import FooterApps from "./FooterApps";

const Footer = ({ dark }) => {
  return (
    <footer
      className={classNames("py-8 py-md-11", dark ? "bg-dark" : "bg-gray-200")}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3">
            {/* <!-- Brand --> */}
            <img
              src={dark ? LogoWhite : Logo}
              alt="..."
              className="img-fluid mb-2"
              style={{ width: 100 }}
            />

            {/* <!-- Text --> */}
            <p className="text-gray-700 mb-2">
              A better way to build chatbots.
            </p>

            {/* <!-- Social --> */}
            <FooterSocial />
          </div>

          {/* <!-- Menu --> */}
          <FooterMenu />

          {/* <!-- Apps --> */}
          <FooterApps />
        </div>
        {/* <!-- / .row --> */}
      </div>
      {/* <!-- / .container --> */}
    </footer>
  );
};

Footer.propTypes = {
  dark: PropTypes.bool,
};

Footer.defaultProps = {
  dark: false,
};

export default Footer;
