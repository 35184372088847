import React from "react";
import { LinksExt } from "../data";

const ContactInfoSection = () => {
  return (
    <section
      className="py-7 py-md-9 border-bottom border-gray-300 bg-gray-200"
      id="info"
    >
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            {/* <!--  Button --> */}
            <a
              href="#info"
              className="btn btn-white btn-rounded-circle shadow mt-n11 mt-md-n13"
              data-scroll
            >
              <i className="fe fe-arrow-down"></i>
            </a>
          </div>
        </div>
        {/* <!--  / .row --> */}
        <div className="row">
          <div className="col-12 text-center">
            {/* <!--  Heading --> */}
            <h6 className="text-uppercase text-gray-700 mb-1">Email us</h6>

            {/* <!--  Link --> */}
            <a href={`mailto:${LinksExt.SupportEmail}`} className="h4">
              {LinksExt.SupportEmail}
            </a>
          </div>
        </div>{" "}
        {/* <!--  / .row --> */}
      </div>
    </section>
  );
};

export default ContactInfoSection;
