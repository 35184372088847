import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Logo from "../../images/logos/ChatIQ_Logo_Blue.svg";
import LogoWhite from "../../images/logos/ChatIQ_Logo_White.svg";
import HeaderMenu from "./HeaderMenu";
import { LinksInt, LinksExt } from "../../data";
import { Link } from "gatsby";

// Transition
const transitionDuration = 200;

const Header = ({ dark }) => {
  const [show, setShow] = useState(false);
  const [collapsing, setCollapsing] = useState(false);
  const handleShow = () => {
    if (collapsing) return;
    setCollapsing(true);
    setTimeout(() => {
      setCollapsing(false);
      setShow(true);
    }, transitionDuration);
  };
  const handleHide = () => {
    if (collapsing) return;
    setCollapsing(true);
    setTimeout(() => {
      setCollapsing(false);
      setShow(false);
    }, transitionDuration);
  };

  return (
    <nav
      className={classNames(
        "navbar navbar-expand-lg fixed-top",
        dark ? "navbar-dark bg-dark" : "navbar-light bg-white"
      )}
    >
      <div className="container">
        {/* Brand */}
        <Link className="navbar-brand" to={LinksInt.Homepage}>
          <img
            src={dark ? LogoWhite : Logo}
            className="navbar-brand-img"
            alt="..."
            style={{ width: 100 }}
          ></img>
        </Link>

        {/* Toggler */}
        <button
          className={classNames("navbar-toggler", { collapsed: !show })}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={handleShow}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Collapse */}
        <div
          className={classNames("navbar-collapse", {
            show: show,
            collapse: !collapsing,
            collapsing: collapsing,
          })}
          id="navbarCollapse"
        >
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={handleHide}
          >
            <i className="fe fe-x"></i>
          </button>

          {/* Navigation */}
          <HeaderMenu />

          {/* Buttons */}
          <a
            className="navbar-btn btn btn-sm btn-primary-soft lift me-2"
            href={LinksExt.LogIn}
          >
            Log In
          </a>
          <a
            className="navbar-btn btn btn-sm btn-primary lift"
            href={LinksExt.SignUp}
          >
            Sign Up
          </a>
        </div>
      </div>
    </nav>
  );
};

Header.propTypes = {
  dark: PropTypes.bool,
};

Header.defaultProps = {
  dark: false,
};

export default Header;
