import React from "react";
import { LinksExt } from "../data";
import Illustration from "../images/illustrations/illustration-4.png";

const FeaturesWelcomeSection = () => {
  return (
    <section className="pt-4 pt-md-11">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-5 col-lg-6 order-md-2">
            {/* Image */}
            <img
              src={Illustration}
              className="img-fluid mw-md-150 mw-lg-130 mb-6 mb-md-0"
              alt="..."
              data-aos="fade-up"
              data-aos-delay="100"
            />
          </div>
          <div
            className="col-12 col-md-7 col-lg-6 order-md-1"
            data-aos="fade-up"
          >
            {/* Heading */}
            <h1 className="display-3 text-center text-md-start">
              Feature-rich platform, yet easy to use
            </h1>

            {/* Text */}
            <p className="lead text-center text-md-start text-muted mb-6 mb-lg-8">
              ChatIQ offers a wide range of features designed for you to build
              long-lasting customer relationships.
            </p>

            {/* Buttons */}
            <div className="text-center text-md-start">
              <a
                href={LinksExt.SignUp}
                className="btn btn-primary shadow lift me-2"
              >
                Get Started
                <i className="fe fe-arrow-right d-none d-md-inline ms-3"></i>
              </a>
            </div>
          </div>
        </div>
        {/* / .row  */}
      </div>
      {/* / .container */}
    </section>
  );
};

export default FeaturesWelcomeSection;
