import React from "react";

import AppCard from "../components/AppCard";
import { integrations } from "../data";

const IntegrationsSection = () => {
  return (
    <section className="mt-5">
      <div className="container mt-10" id="integrations-page">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">
            {/* <!-- Heading --> */}
            <h1 className="display-2 text-primary">Integrations</h1>

            {/* <!-- Text --> */}
            <p className="lead text-blue-100 mb-6 mb-md-8">
              Easily integrate 3rd party apps to ChatIQ.
              <br />
              Integrations make it easy to work with the applications your teams
              already love.
            </p>
          </div>
        </div>
        {/* <!-- / .row --> */}
      </div>
      {/* <!-- / .container --> */}
      <div className="container">
        <div className="row">
          {integrations.map(({ id, name, icon, type, border }) => (
            <div key={id} className="col-12 col-md-6 col-lg-4">
              {/* <!-- Card --> */}
              <AppCard name={name} icon={icon} type={type} border={border} />
            </div>
          ))}
          <div className="col-12 col-md-6 col-lg-4">
            <AppCard name={"And more..."} border={""} />
          </div>
        </div>
        {/* <!-- / .row --> */}
      </div>
    </section>
  );
};

export default IntegrationsSection;
