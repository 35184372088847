export const LinksInt = {
  Homepage: "/",
  TermsOfService: "/terms-of-service",
  PrivacyPolicy: "/privacy-policy",
  Contact: "/contact",
  About: "/about",
  Pricing: "/pricing",
  Features: "/features",
  Integrations: "/integrations",
};
