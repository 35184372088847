import React from "react";
import { Link } from "gatsby";
import { LinksInt } from "../../data";

const HeaderMenu = () => {
  return (
    <ul className="navbar-nav ms-auto me-auto">
      <li className="nav-item">
        <Link className="nav-link" id="navbarHome" to={LinksInt.Homepage}>
          Home
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" id="navbarFeatures" to={LinksInt.Features}>
          Features
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className="nav-link"
          id="navbarIntegrations"
          to={LinksInt.Integrations}
        >
          Integrations
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" id="navbarPricing" to={LinksInt.Pricing}>
          Pricing
        </Link>
      </li>
    </ul>
  );
};

export default HeaderMenu;
