import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Typed from "typed.js";

const defaultOptions = {
  typeSpeed: 40,
  backSpeed: 40,
  backDelay: 1000,
  loop: true,
};

const TypedText = ({ strings, options }) => {
  const ref = useRef(null);
  useEffect(() => {
    const typed = new Typed(ref.current, {
      strings,
      ...defaultOptions,
      ...options,
    });
    return () => {
      typed.destroy();
    };
  }, [strings, options]);
  return <span ref={ref} />;
};

TypedText.propTypes = {
  strings: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.object,
};

export default TypedText;
