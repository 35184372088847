import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import AOS from "aos";

import "../../styles/libs.scss";
import "../../styles/theme.scss";
import "../../styles/app.scss";
import "aos/dist/aos.css";

import Header from "../Header";
import Footer from "../Footer";

const Layout = ({ title, darkHeader, darkFooter, children }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <main style={{ paddingTop: 85 }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header dark={darkHeader} />
      {children}
      <Footer dark={darkFooter} />
    </main>
  );
};

Layout.propTypes = {
  title: PropTypes.string,
  darkHeader: PropTypes.bool,
  darkFooter: PropTypes.bool,
  children: PropTypes.node,
};

Layout.defaultProps = {
  title: "ChatIQ",
  darkHeader: false,
  darkFooter: false,
  children: undefined,
};

export default Layout;
