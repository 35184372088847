import React from "react";
import Svg from "react-inlinesvg";
import TypedText from "../components/TypedText";
import { LinksExt } from "../data";
import Photo from "../images/photos/photo-2.jpg";
import Customers from "../images/screenshots/platform/customers.png";
import Repeat from "../images/duotone-icons/media/repeat.svg";
import Code from "../images/duotone-icons/code/code.svg";
import Curve from "../images/shapes/curves/curve-1.svg";

const AboutSection = () => {
  return (
    <section className="pt-8 pt-md-11 bg-gradient-light-white">
      <div className="container">
        <div className="row align-items-center justify-content-between mb-8 mb-md-11">
          <div className="col-12 col-md-6 order-md-2">
            {/* <!-- Heading --> */}
            <h2>
              All-in-one customer support platform for{" "}
              <span className="text-success">
                <TypedText
                  strings={["startups.", "companies.", "enterprises."]}
                />
              </span>
            </h2>

            {/* <!-- Text --> */}
            <p className="fs-lg text-muted mb-6">
              Provide outstanding service to your customers where they are, from
              voice to chat, SMS, email and WhatsApp in a single screen.
            </p>

            {/* <!-- Text --> */}
            <p className="fs-lg text-muted mb-6">
              Integrate payment gateways to provide invoicing and payments
              instantly during a conversation within your customer support
              platform.
            </p>

            <p className="fs-lg text-muted mb-6">
              Automate up to 60% of your repetitive customer support tasks, with
              a help of AI-powered bot functionality, which is supported in 100+
              languages.
            </p>

            {/* <!-- List --> */}
            <div className="row">
              <div className="col-12 col-lg-6">
                {/* <!-- Item --> */}
                <div className="d-flex">
                  {/* <!-- Check --> */}
                  <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                    <i className="fe fe-check"></i>
                  </div>

                  {/* <!-- Text --> */}
                  <p className="text-success">Lifetime updates</p>
                </div>

                {/* <!-- Item --> */}
                <div className="d-flex">
                  {/* <!-- Check --> */}
                  <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                    <i className="fe fe-check"></i>
                  </div>

                  <p className="text-success mb-lg-0">100+ Languages</p>
                </div>
              </div>
              <div className="col-12 col-lg-6 mb-6 mb-md-0">
                {/* <!-- Item --> */}
                <div className="d-flex">
                  {/* <!-- Check --> */}
                  <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                    <i className="fe fe-check"></i>
                  </span>

                  {/* <!-- Text --> */}
                  <p className="text-success">Tech support</p>
                </div>

                {/* <!-- Item --> */}
                <div className="d-flex">
                  {/* <!-- Check --> */}
                  <div className="badge badge-rounded-circle bg-success-soft me-1 me-4">
                    <i className="fe fe-check"></i>
                  </div>

                  {/* <!-- Text --> */}
                  <p className="text-success mb-0">Integration ready</p>
                </div>
              </div>
            </div>
            {/* <!-- / .row --> */}
          </div>
          <div className="col-12 col-md-6 col-lg-5 order-md-1">
            {/* <!-- Card --> */}
            <div className="card shadow-light-lg lift lift-lg">
              {/* <!-- Image --> */}
              <img src={Photo} alt="..." className="card-img-top" />

              {/*<!-- Shape --> */}
              <div className="position-relative">
                <div className="shape shape-bottom shape-fluid-x text-white">
                  <Svg src={Curve}></Svg>
                </div>
              </div>

              {/* <!-- Body --> */}
              <div className="card-body">
                {/* <!-- Form --> */}
                <form method="post" action={LinksExt.ContactForm}>
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control form-control-flush"
                      id="cardName"
                      name="name"
                      placeholder="Name"
                    />
                    <label htmlFor="cardName">Name</label>
                  </div>
                  <div className="form-floating">
                    <input
                      type="email"
                      className="form-control form-control-flush"
                      id="cardEmail"
                      name="email"
                      placeholder="Email"
                    />
                    <label htmlFor="cardEmail">Email</label>
                  </div>
                  <input type="hidden" name="message" value="Demo Request" />
                  <div className="mt-6">
                    <button
                      className="btn w-100 btn-success lift"
                      type="submit"
                    >
                      Request a demo
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- / .row --> */}
        <div id="platform-page" className="row align-items-center">
          <div className="col-12 col-md-7 col-lg-6">
            {/* <!-- Heading --> */}
            <h2>
              Completely renewed platform that is being developed as{" "}
              <span className="text-primary">single page application</span>.
            </h2>

            {/* <!-- Text --> */}
            <p className="fs-lg text-muted mb-6">
              We are renewing the design for better user experience and
              interface.
            </p>

            {/* <!-- List --> */}
            <div className="d-flex">
              {/* <!-- Icon --> */}
              <div className="icon text-primary">
                <Svg width="24" height="24" src={Repeat} />
              </div>

              <div className="ms-5">
                {/* <!-- Heading --> */}
                <h4 className="mb-1">
                  Continious development and support by our developers
                </h4>

                {/* <!-- Text --> */}
                <p className="text-muted mb-6">
                  We've been developing and improving the platform every single
                  day.
                </p>
              </div>
            </div>
            <div className="d-flex">
              {/* <!-- Icon --> */}
              <div className="icon text-primary">
                <Svg width="24" height="24" src={Code} />
              </div>

              <div className="ms-5">
                {/* <!-- Heading --> */}
                <h4 className="mb-1">
                  Responsive applications in different devices
                </h4>

                {/* <!-- Text --> */}
                <p className="text-muted mb-6 mb-md-0">
                  Mobile apps that make it possible for the customer support
                  agents to handle customer enquiries right from their mobile
                  phones.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-6">
            <div className="w-md-150 w-lg-130 position-relative">
              {/* <!-- Image --> */}
              <div className="img-skewed img-skewed-start">
                {/* <!-- Image --> */}
                <img
                  src={Customers}
                  className="screenshot img-fluid img-skewed-item"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
        {/* <!-- / .row --> */}
      </div>
      {/* <!-- / .container --> */}
    </section>
  );
};

export default AboutSection;
