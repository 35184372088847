import React from "react";
import Address from "../components/Address";
import { LinksExt } from "../data";

const PrivacyPolicySection = () => {
  return (
    <section className="pt-8 pt-md-11 pb-8 pb-md-14 bg-gradient-light-white">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md">
            {/* <!-- Heading --> */}
            <h1 className="display-4 mb-2">Privacy Policy</h1>

            {/* <!-- Text --> */}
            <p className="fs-lg text-gray-700 mb-md-0">Updated 11/07/2021</p>
          </div>
        </div>
        {/* <!-- / .row --> */}
        <div className="row">
          <div className="col-12">
            {/* <!-- Divider --> */}
            <hr className="my-6 my-md-8" />
          </div>
        </div>{" "}
        {/* <!-- / .row --> */}
        <div className="row">
          <div className="col-12 col-md-8">
            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>1 - Introduction</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              Welcome to ChatIQ, LLC. (ChatIQ, "we", "us" and "our"). ChatIQ
              provides software and services that unify your email, customer
              communication channels, and apps in one platform, helping
              teammates work better together. The “Websites” means ChatIQ’s
              websites (including without limitation{" "}
              <a href="https://chatiq.net">chatiq.net</a>,{" "}
              <a href="https://www.chatiq.net">www.chatiq.net</a>,{" "}
              <a href="https://web.chatiq.net">web.chatiq.net</a>,{" "}
              <a href="https://app.chatiq.net">app.chatiq.net</a> and any
              successor URLs, mobile or localized versions and related domains
              and subdomains), and the “Services” means ChatIQ's products,
              applications, and services, in each case in whatever format they
              may be offered now or in the future.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>2 - Purpose of this Privacy Policy</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              The purpose of this “Privacy Policy” is to inform you of the type
              of personal data we collect when you use our Services, why we
              collect the data, how it is used and your rights and choices. If
              you do not agree with this Privacy Policy, do not access or use
              our Services.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>3 - Personal Data We Collect</strong>
            </h3>

            {/* <!-- Sub Heading --> */}
            <h4>Personal Data that you provide to us.</h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              When you register for a ChatIQ account you are required to provide
              certain basic contact information, such as your name, and email
              address, and telephone number. If you are registering for a paid
              account you will also be required to provide payment information,
              such as payment card details (collected by our payment service
              provider) as well as other information that you voluntarily choose
              to add to your account profile, like a profile photo or a
              timezone. We will inform you when requesting your Personal Data,
              if certain data must be provided or if it is optional. We will
              also inform you of the consequences should you not wish to provide
              this data. We also collect the information that you may choose to
              submit to our customer support team, for example regarding a
              problem you are experiencing with our Services. This may include
              your contact information, a summary of the problem you are
              experiencing, and any other documentation or information that
              would be helpful in resolving the issue.
            </p>

            {/* <!-- Sub Heading --> */}
            <h4>Automatically collected data.</h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              When you interact with ChatIQ through the Services, we
              automatically collect information about you through cookies (small
              text files placed on your device), mobile device identifier and
              other technologies. Please read the “Cookies” section below to
              learn more about how we use cookies and other technologies. When
              you visit our Websites, our servers record information (“log
              data”), including information that your browser automatically
              sends whenever you visit the Website. This log data includes your
              Internet Protocol (“IP”) address (from which we understand the
              country you are connecting from at the time you visit the Site),
              browser type and settings, the date and time of your request.
            </p>

            {/* <!-- Sub Heading --> */}
            <h4>Data from other services you link to your account.</h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              In order to perform tasks that are part of the Services we require
              you to integrate your third-party email service with your Services
              account. Doing so provides us with access to your email account(s)
              and the content included therein. You authorize this access by
              agreeing to this Privacy Policy and providing any consent required
              by your email provider. We receive information about you when you
              or your administrator integrate or link a third-party service with
              our Services. You or your administrator may also integrate other
              third party services you use with and into our Services. For
              example, you may authorize our Services to access and display
              messages from within an instant messaging application, or to
              access and display files from a third-party document sharing
              service. The information we receive when you integrate our
              Services with a third-party service depends on the settings,
              permissions and privacy policy associated with that third-party
              service. Please check the privacy settings and privacy policy of
              these third-party services to understand what data may be
              disclosed to and shared with us.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>4 - How We Use Personal Data</strong>
            </h3>

            {/* <!-- Text --> */}
            <p>We collect Personal Data for the following purposes:</p>

            {/* <!-- Sub Heading --> */}
            <h4>
              To provide the Services and manage your access to and use of the
              Services
            </h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              We use information about you to provide the Services to you,
              including to register you for the Services, process transactions
              with you, authenticate you when you log in, provide customer
              support, and operate and maintain the Services.
            </p>

            {/* <!-- Sub Heading --> */}
            <h4>
              To communicate with you about the Services and provide you with
              customer support
            </h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              We use your contact information to communicate with you about the
              Services, respond to your customer support questions and requests,
              and to provide you notices regarding the Services (including
              notices about your account, product update announcements, security
              alerts, administrative notifications and the like). In connection
              with providing you with customer support, we may also use the
              information we automatically collect and information that you
              provide to us and consent for us to view and utilize as part of a
              support request so that we can respond to your request, analyze
              information related to your support request, and to repair or
              improve the Services. We may also provide you with tailored
              communications that are based on your interactions with the
              Services. For example, certain actions you take may trigger a
              third-party app suggestion or we may communicate with you as you
              begin using the Services to assist you as you learn to be
              proficient in your use of the Services. These communications are
              fundamental to the Services and in most cases, you cannot opt-out
              of them. If an opt-out is available, you will find information
              about how to opt-out in the communication itself or in your
              account settings.
            </p>

            {/* <!-- Sub Heading --> */}
            <h4>For research and development</h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              So that we can continually improve the functionality of our
              Services, we utilize our collective learnings about the way users
              interact with and use the Services, as well as feedback provided
              directly to us, to troubleshoot issues, fix bugs, enhance
              functionality, and identify areas for integration and improvement
              of the Services.
            </p>

            {/* <!-- Sub Heading --> */}
            <h4>To market and promote our Services</h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              We use your contact information and information about how you use
              the Services to communicate directly with you, including by
              sending you newsletters, promotions or information about current
              and future products and services. You may opt out of receiving
              such communications at any time by (i) clicking the unsubscribe
              link included in all the emails you receive or (ii) contacting us
              as indicated in Section 12 below (“Contact”).
            </p>

            {/* <!-- Sub Heading --> */}
            <h4>For safety and security</h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              We use information about you and the way in which you use the
              Services to verify accounts and activity, monitor for suspicious
              or fraudulent activity and identify violations of our Services
              policies.
            </p>

            {/* <!-- Sub Heading --> */}
            <h4>
              To protect our legitimate business interests and legal rights
            </h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              Where required by law, or where we think it is necessary to
              protect our legal rights and interests or the legal rights and
              interests of others, we use information about you in connection
              with legal claims, compliance and regulatory functions. If you ask
              us to delete your data or to be removed from our marketing lists
              and we are required to fulfill your request, we will keep basic
              data to identify you and prevent further unwanted processing. As
              used above, “legitimate interests” means our interests in
              conducting our business, managing and delivering the best Services
              to you. We will not use your Personal Data for activities where
              the impact on you overrides our interests, unless we have your
              consent or those activities are otherwise required or permitted by
              law.
            </p>

            {/* <!-- Sub Heading --> */}
            <h4>With your consent</h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              We use Personal Data about you where you have given us consent to
              do so for a specific purpose not listed above. For example, with
              your permission we may publish testimonials or featured customer
              stories on our Websites to promote the Services, including names
              and profile photo of representatives of our customers alongside
              the testimonial. While providing our Services, we may collect on
              behalf of our customers information related to our customers’
              employees, customers and other individuals. Our use of information
              on behalf of our customers is governed by our contract with the
              applicable customer and the customer’s own privacy policies. We
              are not responsible for the privacy policies or privacy practices
              of customers or other third parties.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>5 - European Union (EU) Individuals</strong>
            </h3>

            {/* <!-- Sub Heading --> */}
            <h4>Scope</h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              This section applies if you are an individual in the EU (for these
              purposes, reference to the EU also includes the European Economic
              Area countries of Iceland, Liechtenstein and Norway and, to the
              extent applicable, Switzerland).
            </p>

            {/* <!-- Sub Heading --> */}
            <h4>Data Controller</h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              ChatIQ, LLC. is the data controller for the processing of your
              Personal Data. We act as a data processor (or service provider) in
              relation to the Services we provide to our Customers.
            </p>

            {/* <!-- Sub Heading --> */}
            <h4>Your Rights</h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              Subject to EU data protection law, you have the following rights
              in relation to your Personal Data:
            </p>

            {/* <!-- List --> */}
            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <strong>Right of access:</strong> If you ask us, we will confirm
                whether we are processing your Personal Data and if so, provide
                you with a copy of that Personal Data along with certain other
                details. If you require additional copies, we may need to charge
                a reasonable fee.
              </p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <strong>Right to rectification:</strong> If your Personal Data
                is inaccurate or incomplete, you are entitled to ask that we
                correct or complete it. If we shared your Personal Data with
                others, we will tell them about the correction where possible.
                If you ask us, and where possible and lawful to do so, we will
                also tell you with whom we shared your Personal Data so you can
                contact them directly.
              </p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <strong>Right to erasure:</strong> You may ask us to delete or
                remove your Personal Data, such as where you withdraw your
                consent. If we shared your data with others, we will alert them
                to the need for erasure where possible. If you ask us, and where
                possible and lawful to do so, we will also tell you with whom we
                shared your Personal Data with so you can contact them directly.
              </p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <strong>Right to restrict processing:</strong> You may ask us to
                restrict or ‘block’ the processing of your Personal Data in
                certain circumstances, such as where you contest the accuracy of
                the data or object to us processing it (please read below for
                information on your right to object). We will tell you before we
                lift any restrictions on processing. If we shared your Personal
                Data with others, we will tell them about the restriction where
                possible. If you ask us, and where possible and lawful to do so,
                we will also tell you with whom we shared your Personal Data so
                you can contact them directly.
              </p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <strong>Right to data portability:</strong> You have the right
                to obtain your Personal Data from us that you consented to give
                us or that was provided to us as necessary in connection with
                our contract with you where we process that Personal Data in an
                automated way. We will give you your Personal Data in a
                structured, commonly used and machine-readable format. You may
                reuse it elsewhere.
              </p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <strong>Right to object:</strong> You may ask us at any time to
                stop processing your Personal Data, and we will do so:
              </p>
            </div>

            <div className="d-flex ms-7">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                If we are relying on a legitimate interest to process your
                Personal Data -- unless we demonstrate compelling legitimate
                grounds for the processing; or
              </p>
            </div>
            <div className="d-flex ms-7">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                If we are processing your Personal Data for direct marketing
                (you can always object using the unsubscribe link in our
                marketing communications).
              </p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <strong>Right to withdraw consent:</strong> If you have
                consented to our use of your Personal Data for a specific
                purpose, you have the right to change your mind at any time, but
                this will not affect any processing of your data that has
                already taken place.
              </p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <strong>
                  Right to make a complaint with the data protection authority:
                </strong>{" "}
                If you have a concern about our privacy practices, including the
                way we handled your Personal Data, you can report it to the data
                protection authority that is authorized to hear those concerns.
              </p>
            </div>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              Please go to <strong>“Contact”</strong> for information on how to
              exercise your rights.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>6 - Disclosure of Personal Data</strong>
            </h3>

            {/* <!-- Sub Heading --> */}
            <h4>Personnel, service providers</h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              Only our personnel, service providers and trusted third-party
              applications have access to your Personal Data. These parties
              include hosting, back-up and other IT services providers, website
              analytics companies, providers of digital advertising services,
              providers of CRM, marketing and sales software solutions, billing
              and processing payments functions. We only provide such parties
              with the information that they need to perform the services we
              have hired them to provide.
            </p>

            {/* <!-- Sub Heading --> */}
            <h4>Our Subsidiaries and Affiliates</h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              ChatIQ is managed and operates primarily in the United States.
            </p>

            {/* <!-- Sub Heading --> */}
            <h4>Legal Requirements</h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              We may disclose your Personal Data if required to do so by law or
              in the good faith belief that such action is necessary to (i)
              comply with a legal obligation or lawful requests by public
              authorities, including to meet national security or law
              enforcement requirements, (ii) protect and defend the rights or
              property of ChatIQ, (iii) act in urgent circumstances to protect
              the personal safety of users of the Services or the public, or
              (iv) protect against legal liability.
            </p>

            {/* <!-- Sub Heading --> */}
            <h4>Business Transfers</h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              As we develop our business, we might sell or buy businesses or
              assets. In the event of a corporate sale, merger, reorganization,
              dissolution or similar event, Personal Data may be part of the
              transferred assets.
            </p>

            {/* <!-- Sub Heading --> */}
            <h4>With Your Consent</h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              We may share Personal Data about you with third parties when you
              give us consent to do so. For example, with your permission, we
              may publish and display testimonials or featured customer stories
              on our Websites, including names and profile photo of
              representatives of our customers alongside the testimonial.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>7 - Data Retention</strong>
            </h3>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <strong>1 - General Provisions</strong>
                <br />
                ChatIQ may retain your Personal Data for a period of time
                consistent with the original purpose of collection. For
                instance, we may retain your Personal Data during the time you
                have an account to use our Websites or Services, or as agreed in
                our subscription agreements, and for a reasonable period of time
                thereafter. We also may retain your Personal Data as necessary
                to comply with our legal obligations, to establish proof of a
                right or a contract, resolve disputes, enforce our agreements,
                in accordance with the law.
              </p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <strong>
                  2 - Provisions specific to credit card information
                </strong>
                <br />
                Your credit card information is stored no longer than the time
                necessary to allow the fulfillment of the transaction, except in
                the case of a subscription, to facilitate the payment of regular
                customers. In that case, the credit card information is stored
                for the whole duration of your subscription and at least until
                the date at which you carry out your last transaction. Such
                storage is implemented by our secured payment service provider
                Stripe. By subscribing to our Services, you agree to this
                storage.
              </p>
            </div>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>8 - Data Security</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              We take all necessary precautions, as well as appropriate
              organizational and technical measures, to maintain the security,
              integrity, and confidentiality of your Personal Data, and in
              particular to prevent it from being altered or damaged and to
              prevent any unauthorized third party from accessing it. We also
              use secured payment systems consistent with the state of the art
              and the applicable laws and regulations.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>9 - Cookies</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              Cookies are small text files that are placed on your computer or
              mobile device when you visit a website, mobile app or use an
              online platform. Cookies are widely used by online service
              providers to facilitate and help to make the interaction between
              users and websites, mobile apps and online platforms faster and
              easier, as well as to provide reporting information. For more
              information about cookies and their impact on you and your
              browsing visit{" "}
              <a href="https://aboutcookies.org/">www.aboutcookies.org</a>
            </p>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              Cookies set by the website and/or mobile app and/or platform owner
              (in this case, ChatIQ) are called "first party cookies". Cookies
              set by parties other than the website and/or mobile app and/or
              platform owner are called "third party cookies". Third-party
              cookies enable third party features or functionality to be
              provided on or through the website and/or mobile app and/or
              platform (e.g. like advertising, interactive content, and
              analytics). The parties that set these third party cookies can
              recognize your computer or device both when it visits the website
              and/or mobile app and/or platform in question and also when it
              visits certain other websites and/or mobile apps and/or platforms.
              By choosing to use our the Websites after having been notified of
              our use of cookies in the ways described in this Privacy Policy,
              and, in applicable jurisdictions, through notice and unambiguous
              acknowledgment of your consent, you agree to such use.
            </p>

            {/* <!-- Sub Heading --> */}
            <h4>Why do we use cookies?</h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              We use first-party and third-party cookies for several reasons.
              Some cookies are required for technical reasons in order for our
              Websites and/or App and/or platform to operate, and we refer to
              these as "essential" or "strictly necessary" cookies. Other
              cookies also enable us to track and target the interests of our
              users to enhance the experience on our Websites and/or App and/or
              platform. Third parties serve cookies through our Websites and/or
              App and/or platform for analytics and other purposes. This is
              described in more detail below.
            </p>

            {/* <!-- Sub Heading --> */}
            <h4>Our Websites cookies</h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">Essential website cookies</p>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <strong>Purpose:</strong> These cookies are strictly necessary
                to provide you with services available through our Websites and
                to use some of its features.
              </p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <strong>Vendor:</strong>
              </p>
            </div>

            <div className="d-flex ms-7">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">ChatIQ, LLC</p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <strong>How to refuse:</strong> Because these cookies are
                strictly necessary to deliver the Websites and the Services to
                you, you cannot refuse them.
              </p>
            </div>

            {/* <!-- Text --> */}
            <p className="text-gray-800">Analytics and customization cookies</p>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <strong>Purpose:</strong> These cookies collect information that
                is used either in aggregate form to help us understand how our
                Websites are being used or how effective our marketing campaigns
                are, or to help us customize our Websites for you.
              </p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <strong>Vendor:</strong>
              </p>
            </div>

            <div className="d-flex ms-7">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">Google Analytics</p>
            </div>

            <div className="d-flex ms-7">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">Albacross</p>
            </div>

            <div className="d-flex ms-7">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">Segment</p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <strong>How to refuse:</strong> To refuse these cookies, please
                follow the instructions below under the heading "Managing
                Cookies” Alternatively, please click on the relevant opt-out
                link below
              </p>
            </div>

            <div className="d-flex ms-7">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <a href="https://tools.google.com/dlpage/gaoptout">
                  Google Analytics
                </a>
              </p>
            </div>
            <div className="d-flex ms-7">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                Albacross does not appear to provide an opt-out link for its
                cookies. For more information about such cookies, please see
                this <a href="https://albacross.com/privacy-policy/">page</a>.
              </p>
            </div>
            <div className="d-flex ms-7">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                Segment does not appear to provide an opt-out link for its
                cookies. For more information about such cookies, please see
                this <a href="https://segment.com/docs/legal/privacy/">page</a>.
              </p>
            </div>

            {/* <!-- Text --> */}
            <p className="text-gray-800">Advertising cookies</p>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <strong>Purpose:</strong> These cookies are used to make
                advertising messages more relevant to you. They perform
                functions like preventing the same ad from continuously
                reappearing, ensuring that ads are properly displayed for
                advertisers, and in some cases selecting advertisements that are
                based on your interests.
              </p>
            </div>
            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <strong>Vendor:</strong>
              </p>
            </div>

            <div className="d-flex ms-7">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">Bing Ads</p>
            </div>

            <div className="d-flex ms-7">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">DoubleClick</p>
            </div>

            <div className="d-flex ms-7">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">Facebook Pixel</p>
            </div>

            <div className="d-flex ms-7">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">Google AdWords</p>
            </div>

            <div className="d-flex">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <strong>How to refuse:</strong> To refuse these cookies, please
                follow the instructions below under the heading "Managing
                cookies" Alternatively, please click on the relevant opt-out
                link below:
              </p>
            </div>

            <div className="d-flex ms-7">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <a href="https://ads.microsoft.com/">Bing Ads</a>
              </p>
            </div>
            <div className="d-flex ms-7">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <a href="https://policies.google.com/technologies/ads">
                  DoubleClick
                </a>
              </p>
            </div>
            <div className="d-flex ms-7">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                Facebook Pixel does not appear to provide an opt-out link for
                its cookies. For more information about such cookies, please see
                this <a href="https://www.facebook.com/policy.php">page</a>.
              </p>
            </div>
            <div className="d-flex ms-7">
              {/* <!-- Badge --> */}
              <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                <i className="fe fe-check"></i>
              </span>

              {/* <!-- Text --> */}
              <p className="text-gray-800">
                <a href="https://policies.google.com/technologies/ads">
                  Google AdWords
                </a>
              </p>
            </div>

            {/* <!-- Sub Heading --> */}
            <h4>Managing cookies</h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              Most internet browsers allow you to erase cookies from your
              computer hard drive, block all cookies (or just third-party
              cookies) or warn you before a cookie is stored on your device.
              Please note, if you choose to block all cookies, our site will not
              function as intended and you will not be able to use or access
              many of the services we provide. If you have blocked all cookies
              and wish to make full use of the features and services we offer,
              you will need to enable your cookies. You can do this in your
              browser. Rather than blocking all cookies, you can choose to only
              block third-party cookies which will still allow our website to
              function as intended.
            </p>

            {/* <!-- Sub Heading --> */}
            <h4>How We Respond to Do Not Track (DNT) Signals</h4>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              Some internet browsers have incorporated “Do Not Track” features
              which, when enabled, causes your browser to send a do not track
              HTTP header file or “signal” to each site you visit. At present,
              the Services do not respond to this type of signal.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>10 - Modifications</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              We reserve the right, at our sole discretion, to modify this
              Privacy Policy or any portion thereof. Any changes will be
              effective from the time of publication of the new privacy policy.
              If we believe that the changes are material, we will let you know
              by doing one (or more) of the following: (i) posting the changes
              on or through the Services, (ii) sending you an email or message
              about the changes. Your continued use of the Services after the
              changes have been implemented shall indicate your agreement with
              the terms of such revised privacy policy. Otherwise, and if the
              new privacy policy does not suit you, you must no longer use the
              Services.
            </p>

            {/* <!-- Heading --> */}
            <h3 className="mb-5">
              <strong>11 - Contact</strong>
            </h3>

            {/* <!-- Text --> */}
            <p className="text-gray-800">
              Have questions or concerns about ChatIQ and privacy? Contact us by
              email at{" "}
              <a href={`mailto:${LinksExt.SupportEmail}`}>
                {LinksExt.SupportEmail}
              </a>{" "}
              or by mail at:
            </p>
            <Address />
          </div>

          <div className="col-12 col-md-4">
            {/* <!-- Card --> */}
            <div className="card shadow-light-lg">
              <div className="card-body">
                {/* <!-- Heading --> */}
                <h4>Have a question?</h4>

                {/* <!-- Text --> */}
                <p className="fs-sm text-gray-800 mb-5">
                  Not sure exactly what we’re looking for or just want
                  clarification? We’d be happy to chat with you and clear things
                  up for you. Anytime!
                </p>

                {/* <!-- Heading --> */}
                <h6 className="fw-bold text-uppercase text-gray-700 mb-2">
                  Email us
                </h6>

                {/* <!-- Text --> */}
                <p className="fs-sm mb-0">
                  <a
                    href={`mailto:${LinksExt.SupportEmail}`}
                    className="text-reset"
                  >
                    {LinksExt.SupportEmail}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* {/* <!-- / .row --> */}
      </div>
      {/* {/* <!-- / .container --> */}
    </section>
  );
};

export default PrivacyPolicySection;
