import React from "react";

const FeaturesInfoSection = () => {
  return (
    <section className="bg-gradient-light-white">
      <div className="container pt-10" id="features-page">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">
            {/* <!-- Heading --> */}
            <h1 className="display-3">Unified Customer Messaging SaaS</h1>

            {/* <!-- Text --> */}
            <p className="lead text-muted mb-6 mb-md-8">
              Connect with your customers where they are.
            </p>
          </div>
        </div>
        {/* <!-- / .row --> */}
      </div>
      {/* <!-- / .container --> */}
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            {/* <!-- Card --> */}
            <div className="card card-border border-primary shadow-lg mb-6 mb-md-8 lift lift-lg">
              <div className="card-body text-center">
                <div className="icon-circle bg-primary text-white mb-5">
                  <i className="fe fe-user-check"></i>
                </div>

                <h4 className="fw-bold">Ticketing redefined</h4>

                <p className="text-gray-700 mb-0">
                  Know exactly which customer is waiting for a reply to a query
                  and which co-worker is getting things done.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            {/* <!-- Card --> */}
            <div className="card card-border border-primary shadow-lg mb-6 mb-md-8 lift lift-lg">
              <div className="card-body text-center">
                <div className="icon-circle bg-primary text-white mb-5">
                  <i className="fe fe-users"></i>
                </div>

                <h4 className="fw-bold">Team collaboration</h4>

                <p className="text-gray-700 mb-0">
                  Get colleagues and teams involved, all you need is to mention
                  them. Keep your team in sync and up to date.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            {/* <!-- Card --> */}
            <div className="card card-border border-primary shadow-lg mb-6 mb-md-8 lift lift-lg">
              <div className="card-body text-center">
                <div className="icon-circle bg-primary text-white mb-5">
                  <i className="fe fe-edit"></i>
                </div>

                <h4 className="fw-bold">Quick answers</h4>

                <p className="text-gray-700 mb-0">
                  Use pre-canned replies or articles from the knowledge base.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            {/* <!-- Card --> */}
            <div className="card card-border border-info shadow-lg mb-6 mb-md-8 lift lift-lg">
              <div className="card-body text-center">
                <div className="icon-circle bg-info text-white mb-5">
                  <i className="fe fe-layers"></i>
                </div>

                <h4 className="fw-bold">Omnichannel support</h4>

                <p className="text-gray-700 mb-0">
                  Connect voice, sms, livechat, e-mail, WhatsApp, Facebook
                  Messenger and more in one shared inbox.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            {/* <!-- Card --> */}
            <div className="card card-border border-info shadow-lg mb-6 mb-md-8 lift lift-lg">
              <div className="card-body text-center">
                <div className="icon-circle bg-info text-white mb-5">
                  <i className="fe fe-users"></i>
                </div>

                <h4 className="fw-bold">Teams and tasks</h4>

                <p className="text-gray-700 mb-0">
                  Managers or team leads can easily create groups of employees
                  with different skills, tasks and responsibilities.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            {/* <!-- Card --> */}
            <div className="card card-border border-info shadow-lg mb-6 mb-md-8 lift lift-lg">
              <div className="card-body text-center">
                <div className="icon-circle bg-info text-white mb-5">
                  <i className="fe fe-edit-3"></i>
                </div>

                <h4 className="fw-bold">Private notes</h4>

                <p className="text-gray-700 mb-0">
                  Take notes and share them with your co-workers, so your team
                  is always up to date.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            {/* <!-- Card --> */}
            <div className="card card-border border-primary-desat shadow-lg mb-6 mb-md-8 lift lift-lg">
              <div className="card-body text-center">
                <div className="icon-circle bg-primary-desat text-white mb-5">
                  <i className="fe fe-pie-chart"></i>
                </div>

                <h4 className="fw-bold">Reports</h4>

                <p className="text-gray-700 mb-0">
                  View various standard reports including response times, agent
                  activity and conversation handling times.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            {/* <!-- Card --> */}
            <div className="card card-border border-primary-desat shadow-lg mb-6 mb-md-8 lift lift-lg">
              <div className="card-body text-center">
                <div className="icon-circle bg-primary-desat text-white mb-5">
                  <i className="fe fe-cpu"></i>
                </div>

                <h4 className="fw-bold">Platform integrations</h4>

                <p className="text-gray-700 mb-0">
                  Integrate with apps or e-commerce platforms such as Shopify,
                  Wordpress to unlock customer value for every conversation.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            {/* <!-- Card --> */}
            <div className="card card-border border-primary-desat shadow-lg mb-6 mb-md-8 lift lift-lg">
              <div className="card-body text-center">
                <div className="icon-circle bg-primary-desat text-white mb-5">
                  <i className="fe fe-globe"></i>
                </div>

                <h4 className="fw-bold">Multilingual</h4>

                <p className="text-gray-700 mb-0">
                  Both the software and system messages are available in several
                  languages. Additional languages can be added.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- / .row --> */}
      </div>
    </section>
  );
};

export default FeaturesInfoSection;
