import Whatsapp from "../images/apps/whatsapp.png";
import Facebook from "../images/apps/fb.jpg";
import Webchat from "../images/apps/webchat.png";
import Telegram from "../images/apps/telegram.png";
import Viber from "../images/apps/viber.png";
import Helpdesk from "../images/apps/helpdesk.png";
import Callcenter from "../images/apps/callcenter.png";
import Email from "../images/apps/email.png";
import Calendar from "../images/apps/calendar-app.png";
import ShortLinks from "../images/apps/short_links.png";
import Giphy from "../images/apps/giphy.jpg";
import Twitter from "../images/apps/twitter.png";
import GoogleDrive from "../images/apps/google_drive.png";
import Done from "../images/apps/done-app.png";
import Line from "../images/apps/line.png";
import Stripe from "../images/apps/stripe.png";
import Dropbox from "../images/apps/dropbox.jpg";
import FullContact from "../images/apps/full_contact.png";
import Salesforce from "../images/apps/salesforce.png";
import Share from "../images/apps/share-message.png";
import Kik from "../images/apps/kik.png";
import Iyzico from "../images/apps/iyzico.png";
import Paylike from "../images/apps/paylike.png";
import Telr from "../images/apps/telr.png";
import Paytabs from "../images/apps/paytabs.png";
import Razorpay from "../images/apps/razorpay.png";

import { IntegrationTypes } from "./integration-types";

export const integrations = [
  {
    id: 0,
    name: "WhatsApp",
    icon: Whatsapp,
    type: IntegrationTypes.ChannelIntegrations,
  },
  {
    id: 1,
    name: "Facebook",
    icon: Facebook,
    type: IntegrationTypes.ChannelIntegrations,
  },
  {
    id: 2,
    name: "Livechat",
    icon: Webchat,
    type: IntegrationTypes.ChannelIntegrations,
  },
  {
    id: 3,
    name: "Telegram",
    icon: Telegram,
    type: IntegrationTypes.ChannelIntegrations,
  },
  {
    id: 4,
    name: "Viber",
    icon: Viber,
    type: IntegrationTypes.ChannelIntegrations,
  },
  {
    id: 5,
    name: "Twitter",
    icon: Twitter,
    type: IntegrationTypes.ChannelIntegrations,
  },
  {
    id: 6,
    name: "Call Center",
    icon: Callcenter,
    type: IntegrationTypes.ChannelIntegrations,
  },
  {
    id: 7,
    name: "Email",
    icon: Email,
    type: IntegrationTypes.ChannelIntegrations,
  },
  {
    id: 8,
    name: "Line",
    icon: Line,
    type: IntegrationTypes.ChannelIntegrations,
  },
  {
    id: 9,
    name: "Kik",
    icon: Kik,
    type: IntegrationTypes.ChannelIntegrations,
  },
  {
    id: 10,
    name: "Stripe",
    icon: Stripe,
    type: IntegrationTypes.PaymentsAndAccounting,
    border: "success",
  },
  {
    id: 11,
    name: "Iyzico",
    icon: Iyzico,
    type: IntegrationTypes.PaymentsAndAccounting,
    border: "success",
  },
  {
    id: 121,
    name: "Paylike",
    icon: Paylike,
    type: IntegrationTypes.PaymentsAndAccounting,
    border: "success",
  },
  {
    id: 13,
    name: "Telr",
    icon: Telr,
    type: IntegrationTypes.PaymentsAndAccounting,
    border: "success",
  },
  {
    id: 14,
    name: "PayTabs",
    icon: Paytabs,
    type: IntegrationTypes.PaymentsAndAccounting,
    border: "success",
  },
  {
    id: 15,
    name: "RazorPay",
    icon: Razorpay,
    type: IntegrationTypes.PaymentsAndAccounting,
    border: "success",
  },
  {
    id: 16,
    name: "Salesforce",
    icon: Salesforce,
    type: IntegrationTypes.Crm,
    border: "info",
  },
  {
    id: 17,
    name: "Helpdesk",
    icon: Helpdesk,
    type: IntegrationTypes.Crm,
    border: "info",
  },
  {
    id: 18,
    name: "Google Drive",
    icon: GoogleDrive,
    type: IntegrationTypes.FileManagement,
    border: "info",
  },
  {
    id: 19,
    name: "Dropbox",
    icon: Dropbox,
    type: IntegrationTypes.FileManagement,
    border: "info",
  },
  {
    id: 20,
    name: "Done Tracker",
    icon: Done,
    type: IntegrationTypes.Analytics,
    border: "info",
  },
  {
    id: 21,
    name: "Giphy",
    icon: Giphy,
    type: IntegrationTypes.SocialAndFun,
    border: "info",
  },
  {
    id: 22,
    name: "Calendar",
    icon: Calendar,
    type: IntegrationTypes.Other,
    border: "info",
  },
  {
    id: 23,
    name: "Short links",
    icon: ShortLinks,
    type: IntegrationTypes.Other,
    border: "info",
  },
  {
    id: 24,
    name: "FullContact",
    icon: FullContact,
    type: IntegrationTypes.Other,
    border: "info",
  },
  {
    id: 25,
    name: "Conversation Share",
    icon: Share,
    type: IntegrationTypes.Other,
    border: "info",
  },
];
