import React from "react";
import { Link } from "gatsby";
import { LinksInt } from "../data";
import Illustration from "../images/illustrations/illustration-3.png";

const FormSubmittedSection = () => {
  return (
    <section>
      <div className="container d-flex flex-column">
        <div className="row align-items-center justify-content-center gx-0 min-vh-100">
          <div
            className="col-8 col-md-6 col-lg-7 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11"
            data-aos="fade-left"
          >
            {/* <!-- Image --> */}
            <img src={Illustration} alt="..." className="img-fluid" />
          </div>
          <div
            className="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11"
            data-aos="fade-right"
          >
            {/* <!-- Heading --> */}
            <h1 className="display-3 fw-bold text-center">Thank You!</h1>

            {/* <!-- Text --> */}
            <p className="mb-5 text-center text-muted">
              The form was submitted successfully.
            </p>

            {/* <!-- Link --> */}
            <div className="text-center">
              <Link className="btn btn-primary" to={LinksInt.Homepage}>
                Go to Homepage
              </Link>
            </div>
          </div>
        </div>
        {/* <!-- / .row --> */}
      </div>
      {/* <!-- / .container --> */}
    </section>
  );
};

export default FormSubmittedSection;
