import React from "react";
import { Link } from "gatsby";
import { LinksInt } from "../../data";

const FooterMenu = () => {
  return (
    <>
      <div className="col-6 col-md-4 col-lg-2">
        {/* <!-- Heading --> */}
        <h6 className="fw-bold text-uppercase text-gray-700">Product</h6>

        {/* <!-- List --> */}
        <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
          <li className="mb-3">
            <Link to={LinksInt.Pricing} className="text-reset">
              Pricing
            </Link>
          </li>
          <li className="mb-3">
            <Link to={LinksInt.Features} className="text-reset">
              Features
            </Link>
          </li>
          <li className="mb-3">
            <Link to={LinksInt.Integrations} className="text-reset">
              Integrations
            </Link>
          </li>
        </ul>
      </div>
      <div className="col-6 col-md-4 col-lg-2">
        {/* <!-- Heading --> */}
        <h6 className="fw-bold text-uppercase text-gray-700">Company</h6>

        {/* <!-- List --> */}
        <ul className="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
          <li className="mb-3">
            <Link to={LinksInt.About} className="text-reset">
              About Us
            </Link>
          </li>
          <li className="mb-3">
            <Link to={LinksInt.Contact} className="text-reset">
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
      <div className="col-6 col-md-4 col-lg-2">
        {/* <!-- Heading --> */}
        <h6 className="fw-bold text-uppercase text-gray-700">Legal</h6>

        {/* <!-- List --> */}
        <ul className="list-unstyled text-muted mb-0">
          <li className="mb-3">
            <Link to={LinksInt.TermsOfService} className="text-reset">
              Terms of Service
            </Link>
          </li>
          <li className="mb-3">
            <Link to={LinksInt.PrivacyPolicy} className="text-reset">
              Privacy Policy
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default FooterMenu;
