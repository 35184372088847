import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const AppCard = ({ name, icon, type, border }) => {
  return (
    <div
      className={classNames(
        "card shadow-lg mb-4 lift lift-lg card-border",
        `border-${border}`
      )}
    >
      <div className="card-body app-card-content">
        {/* <!-- Icon --> */}
        {icon && <img src={icon} alt={name} className="app-card-icon"></img>}
        {/* <!-- Text --> */}
        <div>
          {/* <!-- Name --> */}
          <h4 className="text-gray-700 mb-0">{name}</h4>
          {/* <!-- Type --> */}
          <h6 className="text-gray-700 mb-0">{type}</h6>
        </div>
      </div>
    </div>
  );
};

AppCard.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  border: PropTypes.string,
};

AppCard.defaultProps = {
  name: "",
  icon: "",
  type: "",
  border: "primary",
};

export default AppCard;
