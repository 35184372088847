import React from "react";
import { LinksExt } from "../data";
import Address from "../components/Address";

const ContactFormSection = () => {
  return (
    <section className="pt-8 pt-md-11 pb-8 pb-md-14">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">
            {/* <!--  Heading --> */}
            <h2 className="fw-bold">Let us hear from you directly!</h2>

            {/* <!--  Text --> */}
            <p className="fs-lg text-muted mb-7 mb-md-9">
              We always want to hear from you! Let us know how we can best help
              you and we'll do our very best.
            </p>
          </div>
        </div>{" "}
        {/* <!--  / .row --> */}
        <div className="row justify-content-center">
          <div className="col-12 col-md-12 col-lg-10">
            {/* <!--  Form --> */}
            <form method="post" action={LinksExt.ContactForm}>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group mb-5">
                    {/* <!--  Label --> */}
                    <label className="form-label" htmlFor="contactName">
                      Full name
                    </label>

                    {/* <!--  Input --> */}
                    <input
                      className="form-control"
                      id="contactName"
                      type="text"
                      name="name"
                      placeholder="Full name"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group mb-5">
                    {/* <!--  Label --> */}
                    <label className="form-label" htmlFor="contactEmail">
                      Email
                    </label>

                    {/* <!--  Input --> */}
                    <input
                      className="form-control"
                      id="contactEmail"
                      type="email"
                      name="email"
                      placeholder="hello@domain.com"
                    />
                  </div>
                </div>
              </div>
              {/* <!--  / .row --> */}
              <div className="row">
                <div className="col-12">
                  <div className="form-group mb-7 mb-md-9">
                    {/* <!--  Label --> */}
                    <label className="form-label" htmlFor="contactMessage">
                      What can we help you with?
                    </label>

                    {/* <!--  Input --> */}
                    <textarea
                      className="form-control"
                      id="contactMessage"
                      rows="5"
                      placeholder="Tell us what we can help you with!"
                      name="message"
                    ></textarea>
                  </div>
                </div>
              </div>
              {/* <!--  / .row --> */}
              <div className="row justify-content-center">
                <div className="col-auto">
                  {/* <!--  Submit --> */}
                  <button type="submit" className="btn btn-primary lift">
                    Send message
                  </button>
                </div>
              </div>
              {/* <!--  / .row --> */}
            </form>
            {/* <!-- Address --> */}
            <div className="mb-5 mt-5">
              <Address />
            </div>
          </div>
        </div>
        {/* <!--  / .row --> */}
      </div>
      {/* <!--  / .container --> */}
    </section>
  );
};

export default ContactFormSection;
