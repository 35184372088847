import React from "react";
import { LinksExt } from "../data";

const CtaSection = () => {
  return (
    <section className="py-8 py-md-11 bg-dark">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">
            {/* <!-- Badge --> */}
            <span className="badge rounded-pill bg-gray-700-soft mb-4">
              <span className="h6 fw-bold text-uppercase">Get started</span>
            </span>
            {/* <!-- Heading --> */}
            <h1 className="display-4 text-white">
              Your all-in-one Customer Support Platform
            </h1>

            {/* <!-- Text --> */}
            <p className="fs-lg text-muted mb-6 mb-md-8">
              Communicate with your customers on their favorite channel. Exceed
              their expectations with uninterrupted customer support using the
              omnichannel cloud solution from ChatIQ.
            </p>

            {/* <!-- Button --> */}
            <a href={LinksExt.SignUp} className="btn btn-success lift">
              Get Started <i className="fe fe-arrow-right"></i>
            </a>
          </div>
        </div>
        {/* <!-- / .row --> */}
      </div>
      {/* <!-- / .container --> */}
    </section>
  );
};

export default CtaSection;
