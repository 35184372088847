import React from "react";
import LinkedIn from "../../images/icons/social/linkedin.svg";
import Twitter from "../../images/icons/social/twitter.svg";
import Youtube from "../../images/icons/social/youtube.svg";
import Facebook from "../../images/icons/social/facebook.svg";
import Instagram from "../../images/icons/social/instagram.svg";

const FooterSocial = () => {
  return (
    <ul className="list-unstyled list-inline list-social mb-6 mb-md-0">
      <li className="list-inline-item list-social-item me-3">
        <a
          href="https://www.linkedin.com/company/chatiq"
          className="text-decoration-none"
        >
          <img src={LinkedIn} className="list-social-icon" alt="..." />
        </a>
      </li>
      <li className="list-inline-item list-social-item me-3">
        <a href="https://twitter.com/chat_iq" className="text-decoration-none">
          <img src={Twitter} className="list-social-icon" alt="..." />
        </a>
      </li>
      <li className="list-inline-item list-social-item me-3">
        <a
          href="https://www.youtube.com/channel/UCci3_x9MN4X8h3OmuvLSXhQ"
          className="text-decoration-none"
        >
          <img src={Youtube} className="list-social-icon" alt="..." />
        </a>
      </li>
      <li className="list-inline-item list-social-item me-3">
        <a
          href="https://www.facebook.com/chatiq.net"
          className="text-decoration-none"
        >
          <img src={Facebook} className="list-social-icon" alt="..." />
        </a>
      </li>
      <li className="list-inline-item list-social-item">
        <a
          href="https://www.instagram.com/chatiq.app"
          className="text-decoration-none"
        >
          <img src={Instagram} className="list-social-icon" alt="..." />
        </a>
      </li>
    </ul>
  );
};

export default FooterSocial;
