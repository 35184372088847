import React from "react";
import Photo16 from "../images/photos/photo-16.jpg";
import Photo17 from "../images/photos/photo-17.jpg";
import Photo18 from "../images/photos/photo-18.jpg";
import Photo19 from "../images/photos/photo-19.jpg";
import Photo20 from "../images/photos/photo-20.jpg";
import Photo21 from "../images/photos/photo-21.jpg";

const AboutWelcomeSection = () => {
  return (
    <section className="py-8 py-md-11 border-bottom">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">
            {/* <!-- Headin --> */}
            <h1 className="display-2">Small team. Big hearts.</h1>

            {/* <!-- Text --> */}
            <p className="lead text-muted mb-7 mb-md-9">
              Our focus is always on finding the best people to work with. Our
              bar is high, but you look ready to take on the challenge.
            </p>
          </div>
        </div>{" "}
        {/* <!-- / .row --> */}
        <div className="row">
          <div className="col-12">
            {/* <!-- Image --> */}
            <div className="row gx-4">
              <div className="col-4">
                <img
                  className="img-fluid rounded shadow-lg"
                  src={Photo16}
                  alt="..."
                />
              </div>
              <div className="col-3">
                <img
                  className="img-fluid rounded shadow-lg mb-4"
                  src={Photo17}
                  alt="..."
                />

                <img
                  className="img-fluid rounded shadow-lg"
                  src={Photo18}
                  alt="..."
                />
              </div>
              <div className="col-5">
                <div className="row gx-5 mb-4">
                  <div className="col-5">
                    <img
                      className="img-fluid rounded shadow-lg"
                      src={Photo19}
                      alt="..."
                    />
                  </div>
                  <div className="col-7">
                    <img
                      className="img-fluid rounded shadow-lg"
                      src={Photo20}
                      alt="..."
                    />
                  </div>
                </div>{" "}
                {/* <!-- / .row --> */}
                <img
                  className="img-fluid rounded shadow-lg"
                  src={Photo21}
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
        {/* <!-- / .row --> */}
      </div>
      {/* <!-- / .container --> */}
    </section>
  );
};

export default AboutWelcomeSection;
