import React from "react";

const FaqSection = () => {
  return (
    <section className="pt-15 bg-dark">
      <div className="container pt-8 pt-md-11">
        <div className="row">
          <div className="col-12 col-md-6">
            {/* <!-- Item --> */}
            <div className="d-flex">
              {/* <!-- Badge --> */}
              <div className="badge badge-lg badge-rounded-circle bg-success">
                <span>?</span>
              </div>

              <div className="ms-5">
                {/* <!-- Heading --> */}
                <h4 className="text-white">What is ChatIQ?</h4>

                {/* <!-- Text --> */}
                <p className="text-muted mb-6 mb-md-8">
                  ChatIQ is a customer service and engagement platform that
                  connects your business with your customers across phone, SMS,
                  WebChat, email, social messengers and social media, and any
                  other channel you can imagine, altogether in one place.
                </p>
              </div>
            </div>

            {/* <!-- Item --> */}
            <div className="d-flex">
              {/* <!-- Badge --> */}
              <div className="badge badge-lg badge-rounded-circle bg-success">
                <span>?</span>
              </div>

              <div className="ms-5">
                {/* <!-- Heading --> */}
                <h4 className="text-white">
                  Will I be charged for additional features?
                </h4>

                {/* <!-- Text --> */}
                <p className="text-muted mb-6 mb-md-8">
                  No. New features will come at no extra cost. As we promised,
                  we have simple and straight pricing.
                </p>
              </div>
            </div>

            {/* <!-- Item --> */}
            <div className="d-flex">
              {/* <!-- Badge --> */}
              <div className="badge badge-lg badge-rounded-circle bg-success">
                <span>?</span>
              </div>

              <div className="ms-5">
                {/* <!-- Heading --> */}
                <h4 className="text-white">
                  Can I measure the KPIs of my customer support agents?
                </h4>

                {/* <!-- Text --> */}
                <p className="text-muted mb-6 mb-md-0">
                  Yes. Our easy-to-understand dashboard and detailed reporting
                  feature will give you a complete overview of your agents’
                  performance.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            {/* <!-- Item --> */}
            <div className="d-flex">
              {/* <!-- Badge --> */}
              <div className="badge badge-lg badge-rounded-circle bg-success">
                <span>?</span>
              </div>

              <div className="ms-5">
                {/* 
                <!-- Heading --> */}
                <h4 className="text-white">How does ChatIQ work?</h4>

                {/* <!-- Text --> */}
                <p className="text-muted mb-6 mb-md-8">
                  ChatIQ is a cloud-based B2B SaaS product that will seamlessly
                  bring your customer communication and support channels into
                  one window and easily integrate with your existing tools and
                  services.
                </p>
              </div>
            </div>

            {/* <!-- Item --> */}
            <div className="d-flex">
              {/* <!-- Badge --> */}
              <div className="badge badge-lg badge-rounded-circle bg-success">
                <span>?</span>
              </div>

              <div className="ms-5">
                {/* <!-- Heading --> */}
                <h4 className="text-white">
                  How many times will I be charged?
                </h4>

                {/* <!-- Text --> */}
                <p className="text-muted mb-6 mb-md-8">
                  After 30-day free trial, we will charge your account on a
                  monthly basis. You can choose to be billed annually as well
                  and save on your annual spending. You can cancel your
                  subscription anytime.
                </p>
              </div>
            </div>

            {/* <!-- Item --> */}
            <div className="d-flex">
              {/* <!-- Badge --> */}
              <div className="badge badge-lg badge-rounded-circle bg-success">
                <span>?</span>
              </div>

              <div className="ms-5">
                {/* <!-- Heading --> */}
                <h4 className="text-white">
                  How many conversations does my subscription cover?
                </h4>

                {/* <!-- Text --> */}
                <p className="text-muted mb-6 mb-md-0">
                  Unlimited. Speak with your customers with no limitation.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- / .row --> */}
      </div>
      {/* <!-- / .container --> */}
    </section>
  );
};

export default FaqSection;
