import React from "react";
import ButtonApp from "../../images/buttons/button-app.png";
import ButtonPlay from "../../images/buttons/button-play.png";

const FooterApps = () => {
  return (
    <div className="col-6 col-md-4 col-lg-2">
      {/* <!-- Heading --> */}
      <h6 className="fw-bold text-uppercase text-gray-700">Mobile App</h6>

      <a
        href="https://apps.apple.com/us/app/chatiq-app/id1597728088#?platform=iphone"
        className="text-reset d-block mb-4 mt-4"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={ButtonApp}
          className="img-fluid"
          alt="ChatIQ APP IOS"
          style={{ maxWidth: "155px" }}
        />
      </a>

      <a
        href="https://play.google.com/store/apps/details?id=com.chatiq.mobile"
        className="text-reset d-block"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={ButtonPlay}
          className="img-fluid"
          alt="ChatIQ APP Android"
          style={{ maxWidth: "155px" }}
        />
      </a>
    </div>
  );
};

export default FooterApps;
