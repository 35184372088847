import React from "react";

const Address = () => {
  return (
    <>
      <p className="text-gray-800">
        <strong>ChatIQ, LLC.</strong>
      </p>
      <p className="text-gray-800">2450 Colorado Avenue, Suite 100E</p>
      <p className="text-gray-800">Santa Monica, California, 90404</p>
      <p className="text-gray-800">United States of America</p>
    </>
  );
};

export default Address;
